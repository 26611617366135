<!-- =========================================================================================
    File Name: KnowledgeBaseCategoryQuestion.vue
    Description: Knowledge Base Question - Answer Article
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="knowledge-base-article">
    <init-data></init-data>
    <center></center>
    <div class="vx-row" vs-justify="center">
      <!-- <div class="vx-col w-full md:w-2/5 lg:w-1/4">
        <vx-card title="Latest updates">
          <git-history-timeline />
        </vx-card>
      </div> -->
      <div class="vx-col w-1/4 mb-base">
        <vx-card title="Frequently Asked Questions">
          <ul class="bordered-items">
            <li v-for="que in relatedQuestions" :key="que.id" class="py-2">
              <a :href="que.answerUrl" target="_blank">{{ que.question }}</a>
            </li>
          </ul>
        </vx-card>
      </div>

      <div class="vx-col w-1/2 mt-12 md:mt-0">
        <vx-card>
          <!-- Title -->
          <div class="article-title mb-6">
            <h1>{{ $t('GreetingsTitle') }} {{ layerName }}</h1>
          </div>
          <h5 class="mb-6">
            You’ve come to the right place to publish your content in the physical world and create with this new
            medium. No matter what kind of experience you have in mind, we’re here to help you bring it to life!
          </h5>

          <vs-row vs-w="12">
            <vs-col vs-type="flex" vs-justify="center" vs-offset="4" vs-w="4">
              <vs-button
                size="large"
                class="w-full mr-2 shadow-lg"
                type="gradient"
                color="#7367F0"
                v-on:click="start()"
                gradient-color-secondary="#CE9FFC"
                >Start</vs-button
              >
            </vs-col>
          </vs-row>
        </vx-card>

        <!-- </div> -->
        <br />
      </div>

      <div class="vx-col w-1/4 mb-base">
        <vx-card>
          <div slot="no-body">
            <img
              :src="require('@/assets/images/hoverlay/slack-features.jpeg')"
              alt="content-img"
              class="responsive card-img-top"
            />
          </div>
          <h5 class="mb-2">Need help? Join us on Slack</h5>
          <!-- <p class="text-grey">test</p> -->
          <div class="flex justify-between flex-wrap">
            <a
              class="w-full"
              href="https://join.slack.com/t/hoverlayworkspace/shared_invite/zt-tgbw5a4w-mQwMyNyihSZcGDKz_rfxfw"
              target="_blank"
              rel="noreferrer noopener"
            >
              <vs-button
                class="w-full mt-4 mr-2 shadow-lg"
                type="gradient"
                color="#7367F0"
                gradient-color-secondary="#CE9FFC"
              >
                Join us
              </vs-button></a
            >
          </div>
        </vx-card>
      </div>
    </div>

    <br />
  </div>
</template>

<script>
import InitData from '../../components/InitData.vue'
export default {
  components: {
    InitData,
  },
  data() {
    return {
      layerName: JSON.parse(localStorage.getItem('layerInfo')).name,
      // article: {
      //   lastUpdated: 'Mon Dec 10 2018 07:45:55 GMT+0000 (GMT)',
      //   img: require('@/assets/images/pages/kb-article.jpg'),
      //   topics: [
      //     'A location zone from where the content will be available',
      //     'A virtual object (video, image, 3d model...)',
      //   ],
      // },
      relatedQuestions: [
        {
          id: 0,
          question: 'I am new. How do I get started?',
          answerUrl: 'https://docs.spaces.hoverlay.com/1.0/getting-started',
        },
        {
          id: 1,
          question: 'How will the public find my channel and content?',
          answerUrl: 'https://docs.spaces.hoverlay.com/1.0/tutorials/promoting-your-spaces',
        },
        {
          id: 2,
          question: 'What are Spaces?',
          answerUrl: 'https://docs.spaces.hoverlay.com/1.0/getting-started/terminology',
        },
        {
          id: 3,
          question: 'What content Can I import?',
          answerUrl: 'https://docs.spaces.hoverlay.com/1.0/content-creation-guidelines',
        },
        {
          id: 4,
          question: 'What 3D models can I import',
          answerUrl: 'https://docs.spaces.hoverlay.com/1.0/content-creation-guidelines/3d-models',
        },
        {
          id: 5,
          question: 'How can I test a Space remotely?',
          answerUrl: 'https://docs.spaces.hoverlay.com/1.0/tutorials/testing-a-space',
        },
        {
          id: 5,
          question: 'Where can I find the release notes?',
          answerUrl: 'https://docs.spaces.hoverlay.com/1.0/getting-started/release-notes',
        },
      ],
    }
  },
  methods: {
    start() {
      this.$router.push({
        path: `/spaces`,
      })
    },
    vote() {
      window.open('https://auggies.awexr.com/', '_blank')
    },
  },
  beforeDestroy() {
    this.relatedQuestions = []
    this.layerName = null
  },
  async mounted() {
    this.$emit('changeRouteTitle', 'Question')
  },
}
</script>

<style lang="scss">
/*! rtl:begin:ignore */
#dashboard-analytics {
  .greet-user {
    position: relative;

    .decore-left {
      position: absolute;
      left: 0;
      top: 0;
    }
    .decore-right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media (max-width: 576px) {
    .decore-left,
    .decore-right {
      width: 140px;
    }
  }
}
/*! rtl:end:ignore */
</style>
